import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'div[appRoot]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit { 

  hideCookiesBanner: boolean;

  ngOnInit() {
    this.hideCookiesBanner = !!localStorage.getItem('dlv_cookies_consent') ? true : false;
  }

  public accepted() {
    this.hideCookiesBanner = true;
    localStorage.setItem('dlv_cookies_consent', 'true');
  }

}
