import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'page-city',
  templateUrl: './page-city.component.html',
  styleUrls: ['./page-city.component.scss']
})
export class PageCityComponent implements OnChanges {

  @Input() post;
  @Input() relatedPosts;
  @Input() relatedProducts;
  maps;
  about;
  phones;

  constructor(private ds: DomSanitizer) { }

  ngOnChanges() {
    this.maps = this._setMapsCode(this.post);
    this.about = this._setAboutInfo(this.post);
    this.phones = this._setPhonesInfo(this.post);
  }

  public decode(e) {
    try {
      return JSON.parse(e);
    } catch (error) {
      console.dir(error);
      return [];
    }
  }

  private _setMapsCode(post) {
   return post?.paragraphs?.filter(p => p?.htmlTag?.content === 'maps' ).map(m => {
      const info = m.content?.split(';');
      return {
        url: this.ds.bypassSecurityTrustResourceUrl(info[0]),
        title: info[1] || null
      };
    });
  }
  
  private _setAboutInfo(post) {
    return post?.paragraphs?.find(p => p?.htmlTag?.content === 'info' )?.content;
  }
  private _setPhonesInfo(post) {
    return post?.paragraphs?.find(p => p?.htmlTag?.content === 'phones' )?.content;
  }

}
