<div>
    <main>
        <span>{{post?.parent?.title?.split('.')[0]}}</span>
        <h1>{{post?.title.split('.')[0]}}</h1>
        <h2>{{post?.title.split('.')[1]}}</h2>
        <app-rrss *ngIf="post" [post]="post"></app-rrss>
        <index *ngIf="post?.paragraphs" [items]="post?.paragraphs"></index>
        <app-paragraphs [paragraphs]="post?.paragraphs"></app-paragraphs>
    </main>
    <aside>
        <section appAnalysis *ngIf="post?.analysis?.pros && post?.analysis?.cons" [analysis]="post?.analysis"></section>
        <!-- <div appPostProductCard [product]="relatedProducts?.[0]?.product" [variants]="relatedProducts?.[0]?.variants"></div> -->
        <section appMap *ngFor="let map of maps" [url]="map?.url" [title]="map.title"></section>
        <app-rrss *ngIf="post" [post]="post" [hasTitle]="true"></app-rrss>
        <section appKeyValue *ngIf="about" [title]="'Acerca de ' + post?.title.split('.')[0]" [data]="about" [inline]="true"></section>
        <section appKeyValue *ngIf="phones" [title]="'Teléfonos de interés'" [data]="phones"></section>
    </aside>
</div>
<app-list *ngIf="relatedPosts" [title]="'Qué ver cerca de ' + post?.title.split('.')[0]" [posts]="relatedPosts"></app-list>