<section>
    <h3>{{ title }}</h3>
    <span></span>
    <div>
        <div>
            <div *ngFor="let post of posts">
                <article>
                    <img [src]="post?.image" [alt]="post?.title.split('.')[0]" loading="lazy">
                    <div [routerLink]="['/', post.parent?.slug, post.slug]">
                        <a [routerLink]="['/', post.parent?.slug, post?.slug]">{{post?.title.split('.')[0]}}</a>
                    </div>
                </article>
            </div>
        </div>
    </div>
</section>